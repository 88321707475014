<template>
  <el-container class="marketplace t-white">
    <el-aside class="hidden-xs-only h-100" width="317px">
      <div class="box" v-if="tabindex == 1">
        <div class="d-flex ai-center jc-between">
          <span>{{ $t("market.filter") }}</span>
          <div @click="filterFun" class="d-flex ai-center fs-md hover" style="color:#2AD4AF;">
            <img src="../assets/account/clear.png" class="m-r-5">
            {{ $t("market.clear") }}</div>
        </div>
        <!-- 技能属性 -->
        <p>{{ $t("market.skillTitle") }}</p>
        <div class="btn" style="height: 30px">
          <el-select class="sort" v-model="skillType" @change="pageFun(1)" size="medium">
            <el-option v-for="item in skillList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- 级别 -->
        <p>{{ $t("market.grade") }}</p>
        <div class="btn" style="height: 30px">
          <el-select class="sort" v-model="grade" @change="pageFun(1)" size="medium">
            <el-option value="0" :label="$t('market.gradeList.grade0')"></el-option>
            <el-option value="1" label="1~5"></el-option>
            <el-option value="2" label="6~10"></el-option>
            <el-option value="3" label="11~15"></el-option>
            <el-option value="4" label="16~20"></el-option>
            <el-option value="5" label="21~24"></el-option>
          </el-select>
        </div>
        <!-- <el-radio-group v-model="grade" @change="pageFun(1)">
          <el-radio :label="'1'" class="t-white">1~5</el-radio>
          <el-radio :label="'2'" class="t-white">6~10</el-radio>
          <el-radio :label="'3'" class="t-white">11~15</el-radio>
          <el-radio :label="'4'" class="t-white">16~20</el-radio>
          <el-radio :label="'5'" class="t-white">21~24</el-radio>
        </el-radio-group> -->
        <!-- 排序 -->
        <p>{{ $t("market.sorttitle") }}</p>
        <div class="btn" style="height: 30px">
          <el-select class="sort" v-model="sortType2" @change="pageFun(1)" size="medium">
            <el-option v-for="item in sortTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

      </div>
      <div class="box" v-else>
        <div class="d-flex ai-center jc-between">
          <span>{{ $t("market.filter") }}</span>
          <div @click="filterFun" class="d-flex ai-center fs-md hover" style="color:#2AD4AF;">
            <img src="../assets/account/clear.png" class="m-r-5">
            {{ $t("market.clear") }}</div>
          <!-- <span @click="filterFun"><i class="el-icon-refresh-left t-bule m-r-5"></i>{{ $t("market.clear") }}</span> -->
        </div>
        <!-- 种族 -->
        <p>{{ $t("market.camp") }}</p>
        <div class="btn" style="height: 30px">
          <el-select v-model="camp" @change="pageFun(1)" size="medium">
            <el-option v-for="item in campList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 职业 -->
        <p>{{ $t("market.profession") }}</p>
        <div class="btn" style="height: 30px">
          <el-select v-model="profession" @change="pageFun(1)" size="medium">
            <el-option v-for="item in professionList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- 星级 -->
        <!-- <p>{{ $t("market.starrating") }}</p>
        <div class="btn" style="height: 30px">
          <el-select v-model="starrate" @change="pageFun(1)" size="medium">
            <el-option
              v-for="item in starrateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div> -->
        <!-- 级别 -->
        <!-- <p>{{ $t("market.grade") }}</p>
        <el-radio-group v-model="grade" @change="pageFun(1)">
          <el-radio :label="1">
            <img src="../assets/card/r.png" width="30px" />
          </el-radio>
          <el-radio :label="2">
            <img src="../assets/card/sr.png" width="30px" />
          </el-radio>
          <el-radio :label="3">
            <img src="../assets/card/ssr.png" width="30px" />
          </el-radio>
          <el-radio :label="4">
            <img src="../assets/card/ssrjia.png" width="30px" />
          </el-radio> -->
        <!-- <el-radio >
            <img src="../assets/card/sp.png" width="18px" />
          </el-radio> -->
        <!-- </el-radio-group> -->
        <!-- 排序 -->
        <p>{{ $t("market.sorttitle") }}</p>
        <div class="btn" style="height: 30px">
          <el-select class="sort" v-model="sortType" @change="pageFun(1)" size="medium">
            <el-option v-for="item in sortTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </el-aside>
    <el-main v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
      <div class="tab_box d-flex ai-center t-center">
        <div class="tab_box_item cursor" :class="tabindex == 0?'tab_active':''" @click="tabChange(0)">
          {{$t('market.hero')}}</div>
        <!-- <div class="tab_box_item cursor" :class="tabindex == 1?'tab_active':''" @click="tabChange(1)">
          {{$t('market.dragon')}}</div> -->
      </div>
      <div class=" box d-flex flex-wrap" element-loading-background="rgba(0, 0, 0, 0.5)">
        <el-empty :description="$t('market.nodata')" v-if="isempty"></el-empty>
        <template v-if="!isempty&&tabindex == 0">
          <div class="
            box_item
            d-flex
            flex-column
            jc-center
            ai-center
            py-15
            m-r-20 m-b-30
          " v-for="(item, index) in list" :key="index" @click="toDetail(item)">
            <!-- <el-image src="" alt="" class="img" /> -->
            <!-- <div class="w-100 d-flex ai-center jc-between m-b-10 m-r-10">
              <p></p>
              <p class="t-color2">#{{ item.tokenid }}</p>
            </div> -->
            <div class="relative_box">
              <!-- 背景图 -->
              <img :src="
                require('@/assets/card/itemBigEx' +
                  borderStatus(item.attrs.herostar, item.attrs.grade) +
                  '.png')
              " width="225px" height="315px" class="imgbg" />
              <!-- 英雄图片 -->
              <img :src="require('@/assets/hero/' + item.attrs.heroid + '.png')" width="225px" height="315px"
                class="cardimg" />
              <!-- 边框 -->
              <img :src="
                require('@/assets/card/bg_kuang' +
                  borderStatus(item.attrs.herostar, item.attrs.grade) +
                  '.png')
              " width="250px" height="330px" class="borders" />
              <!-- 角标 tokenid -->
              <template v-if="item.tokenid">
                <div class="icon_box">
                  <p class="fs-md">#{{ item.tokenid }}</p>
                </div>
              </template>
              <!-- 级别 -->
              <div class="grade_img d-flex flex-column">
                <img :src="
                  require('@/assets/card/' +
                    gradeStatus(item.attrs.grade) +
                    '.png')
                " width="65px" />
                <img v-if="item.attrs.is_sp == 1" :src="require('@/assets/card/sp.png')" width="50px" class="m-t-10" />
              </div>
              <!-- 名字和星级 -->
              <div class="price t-color4 bold fs-md t-center">
                <p>Lv.{{ item.attrs.herolevel }} {{ item.hero_name }}</p>
                <div class="star d-flex ai-center jc-center m-t-5">
                  <div class="d-flex ai-center" v-if="item.attrs.herostar <= 5">
                    <img src="@/assets/card/star1big.png" width="18px" v-for="(item, i) in item.attrs.herostar"
                      :key="i" />
                  </div>
                  <div class="d-flex ai-center" v-if="item.attrs.herostar > 5 && item.attrs.herostar <= 9">
                    <img src="@/assets/card/star2big.png" width="18px" v-for="(item, i) in item.attrs.herostar - 5"
                      :key="i" />
                  </div>
                  <div class="d-flex ai-center" v-if="item.attrs.herostar > 9 && item.attrs.herostar <= 11">
                    <img src="@/assets/card/star3big.png" width="18px" v-for="(item, i) in item.attrs.herostar - 9"
                      :key="i" />
                  </div>
                </div>
              </div>
              <!-- 种族 -->
              <img v-if="item.attrs.herocamp != 0" :src="
                require('@/assets/card/bigcamp' + item.attrs.herocamp + '.png')
              " width="43px" class="camp_img" />
              <!-- 职业  2法师3战士4坦克5辅助 -->
              <img :src="
                require('@/assets/card/type' +
                  item.attrs.profession_type +
                  '.png')
              " width="43px" class="pros_img" />
              <!-- 倒计时 -->
              <!-- <div class="px-20 fs-md d-flex flex-column ai-center jc-center timeout">
                <Counts :item="item" :auctionBlock="auctionBlock" @callBack="callBack" />
                <p class="bold m-t-10 t-center">{{ $t("card.timeout") }} </p>
              </div> -->
            </div>
            <!-- 价格 -->
            <div class="w-100 px-20 m-t-10 fs-md d-flex ai-center jc-between">
              <span class="fs-sm">{{ $t("card.currentPrice") }}</span>
              <span class="bid_price bold">{{ item.bid_price | cutZero }} BNB</span>
            </div>
            <!-- 总交易额 -->
            <div class="w-100 px-20 m-t-15 fs-md d-flex ai-center jc-between lh-3">
              <span class="fs-sm">{{ $t("card.totalamount") }}</span>
              <span class="bold">{{ item.total_trade_amount | cutZero }} BNB</span>
            </div>
            <!-- 参与出价 -->
            <el-button class="w-100" @click.stop="toSell(item,'card')"
              v-if="item.assets_status == 13 && infos.userid != item.userid" :disabled="item.disabled">
              {{ $t("market.status.auctionadd") }}</el-button>
            <!-- 拍卖中不可操作 -->
            <div class="opration text-center hasactive2 t-color3 m-t-10 fs-md"
              v-else-if="item.assets_status == 13 && infos.userid == item.userid"
              style="margin-left: 10px; margin-right: 10px">
               {{ $t("market.status.auctioning") }}
            </div>
            <!-- 出售 -->
            <el-button class="w-100" @click.stop="toSell(item,'card')"
              v-else-if="item.assets_status == 12 && infos.userid == item.userid">
              {{ $t("market.status.auction") }}
            </el-button>
            <!-- 其他状态 -->
            <div class="w-100 py-10 my-10 text-center default t-color2 notallow" v-else>
              {{ status(item.assets_status) }}
            </div>
            <!-- 倒计时 -->
            <div class="d-flex ai-center m-t-10">
              <p class="bold t-center fs-md m-r-5">{{ $t("card.timeout") }} </p>
              <Counts :item="item" :auctionBlock="auctionBlock" @callBack="callBack" />
            </div>
          </div>
        </template>
        <template v-if="!isempty&&tabindex == 1">
          <div class="
            box_item box_item2
            d-flex
            flex-column
            jc-center
            ai-center
            py-15
            m-r-20 m-b-30
          " v-for="(item, index) in dragonlist" :key="index" @click="toDetail(item)">
            <!-- <div class="w-100 d-flex ai-center jc-center m-b-10 m-r-10">
              <p class="item_title">LV.{{item.level}} {{ item.dragonname }}</p>
            </div> -->
            <!-- 背景图 -->
            <div class="imgbg_box relative">
              <div class="jiaobiao"><span>#{{ item.tokenid }}</span></div>
              <img :src="require(`@/assets/dragon/dragon${item.artifactid}.png`)" class="dragon" />
              <!-- <div class="px-20 fs-md d-flex flex-column ai-center jc-center timeout">
                <Counts :item="item" :auctionBlock="auctionBlock" @callBack="callBack" />
                <p class="bold m-t-10 t-center">{{ $t("card.timeout") }} </p>
              </div> -->
              <div class="block bold t-center" style="color:#F3E9CA;">LV.{{item.level}}{{ item.dragonname }}</div>
            </div>
            <!-- 价格 -->
            <div class="w-100 px-10 m-t-20 fs-md d-flex ai-center jc-between">
              <span class="fs-sm">{{ $t("card.currentPrice") }}</span>
              <span class="bid_price bold">{{ item.price | cutZero }} BNB</span>
            </div>
            <!-- 总交易额 -->
            <div class="w-100 px-10 m-t-15 fs-md d-flex ai-center jc-between lh-3">
              <span class="fs-sm">{{ $t("card.totalamount") }}</span>
              <span class="bid_price bold">{{ item.total_trade_amount | cutZero }} BNB</span>
            </div>
            <!-- 参与出价 -->
            <el-button class="w-100" @click.stop="toSell(item,'dragon')"
              v-if="item.assets_status == 13 && infos.userid != item.userid" :disabled="item.disabled">
              {{ $t("market.status.auctionadd") }}</el-button>
            <!-- 拍卖中不可操作 -->
            <div class="opration text-center hasactive2 t-color3 m-t-15 fs-md"
              v-else-if="item.assets_status == 13 && infos.userid == item.userid">
               {{ $t("market.status.auctioning") }}
            </div>
            <!-- 购买 -->
            <div class="buy_btn t-center cursor" v-else-if="item.assets_status == 12 && infos.userid == item.userid"
              @click.stop="buyFun(item)">{{$t('card.buy')}}</div>
            <!-- 其他状态 -->
            <div class="w-100 py-10 my-10 text-center default t-color2 notallow" v-else>
              {{ status(item.assets_status) }}
            </div>
            <!-- 倒计时 -->
            <div class="d-flex ai-center m-t-10">
              <p class="bold t-center fs-md m-r-5">{{ $t("card.timeout") }} </p>
              <Counts :item="item" :auctionBlock="auctionBlock" @callBack="callBack" />
            </div>
          </div>
        </template>
      </div>
      <Pagination :meta="meta" @pageFun="pageFun"></Pagination>
    </el-main>
    <!-- 购买弹框 -->
    <el-dialog :visible.sync="buyShow" :title="$t('market.status.buy')" center :modal="false">
      <div class="t-white">
        <p>{{ $t("market.buydragon") }}</p>
        <div class="input_item d-flex ai-center jc-between m-t-10">
          <p>#{{infoObj.tokenid}}</p>
          <p>LV.{{infoObj.level}} {{ infoObj.dragonname }}</p>
        </div>
      </div>
      <div class="t-white m-t-25">
        <p class="d-flex ai-center jc-between">{{ $t("market.Amount") }} <span
            class="t-color2 fs-sm">{{ $t("trans.able") }}:
            {{ balance | Fixed(4) }} BNB</span>
        </p>
        <div class="input_item d-flex ai-center jc-between m-t-10">
          <p>{{infoObj.price | cutZero}}</p>
          <p>BNB</p>
        </div>
      </div>
      <el-button class="w-100 m-t-25" :loading="gunState" @click="toBuy" :disabled="disabled">
        {{ $t("messageBox.confirm") }}</el-button>
      <p style="color:#D71818;" class="fs-sm bold m-t-15">{{ $t("market.buydragontip") }} </p>
    </el-dialog>
    <!-- <el-dialog :visible.sync="buyShow" :title="$t('market.status.buy')" center :modal="false">
      <el-form label-position="top">
        <el-form-item :label="$t('trans.name')">
          <div class="input_box">
            <el-input :placeholder="'#' + infoObj.tokenid" autocomplete="off" readonly>
              <template #suffix>Lv.{{ infoObj.attrs.herolevel }} Larmor</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="w-100 d-flex ai-center jc-between">
              <p>{{ $t("trans.sellAt") }}</p>
              <p class="t-color2">
                {{ $t("trans.able") }}: {{ balance | Fixed(4) }} BNB
              </p>
            </div>
          </template>
          <div class="input_box">
            <el-input v-model="infoObj.order_price" :placeholder="$t('market.sort.PriceL')" autocomplete="off" clearable
              readonly>
              <template #suffix>BNB</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item class="tc">
          <el-button class="w-100" :loading="gunState" @click="toBuy" :disabled="disabled">
            {{ $t("messageBox.confirm") }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <!-- 出售卡片 -->
    <sellDom ref="sellDom" @callBack="callBack" :sellObj="sellObj" />
    <bot />
  </el-container>
</template>>

<script>
import Pagination from "@/components/Pagination.vue";
import setData from "../common/set";
import sellDom from "@/components/sellDom.vue";
import Counts from "@/components/Counts.vue";
import { mapState } from "vuex";
import textdata from "../common/dataJaon/text_data.json";
import partner_data1 from "../common/dataJaon/partner_data1.json";
import { cancelCards, cancelCardsCallback, getInfos } from "@/api/common/index.api";
import moment from "moment";
import BigBumber from "bignumber.js";
export default {
  data() {
    return {
      loading: false,
      isempty: false,
      menuShow: true,
      meta: {
        current: 1,
        last: 1,
        total: 0,
      },
      list: [],
      dragonlist: [],
      camp: 0, //this.$storage.get("query").category
      profession: 0,
      starrate: 0,
      grade: "",
      buyShow: false,
      gun_name: "",
      gunState: false,
      price: "",
      infoObj: {},
      balance: "",
      disabled: true,

      sellObj: {},
      auctionBlock: 0,
      sortType: "new,desc",
      sortType2: "new,desc",
      skillType: 0,

      tabindex: this.$storage.get('tabindex') ? this.$storage.get('tabindex') : 0,
    };
  },
  components: { Pagination, sellDom, Counts },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
      isLogin: (state) => state.isLogin,
      sets: (state) => state.sets,
      serverid: (state) => state.serverid,
    }),
    sortTypeList() {
      return [
        { value: "new,desc", label: this.$t("market.sort.latest") },
        { value: "price,asc", label: this.$t("market.sort.lowP") },
        { value: "price,desc", label: this.$t("market.sort.hightP") },
      ];
    },
    campList() {
      return [
        { value: 0, label: this.$t("market.campList.element1") },
        { value: 1, label: this.$t("market.campList.element2") },
        { value: 2, label: this.$t("market.campList.element3") },
        { value: 3, label: this.$t("market.campList.element4") },
        { value: 4, label: this.$t("market.campList.element5") },
        { value: 5, label: this.$t("market.campList.element6") },
      ];
    },
    professionList() {
      return [
        { value: 0, label: this.$t("market.professionList.element1") },
        { value: 2, label: this.$t("market.professionList.element3") },
        { value: 3, label: this.$t("market.professionList.element2") },
        { value: 4, label: this.$t("market.professionList.element4") },
        { value: 5, label: this.$t("market.professionList.element5") },
      ];
    },
    starrateList() {
      return [
        { value: 0, label: this.$t("market.starrateList.star0") },
        { value: 1, label: this.$t("market.starrateList.star1") },
        { value: 2, label: this.$t("market.starrateList.star2") },
        { value: 3, label: this.$t("market.starrateList.star3") },
        { value: 4, label: this.$t("market.starrateList.star4") },
        { value: 5, label: this.$t("market.starrateList.star5") },
        { value: 6, label: this.$t("market.starrateList.star6") },
        { value: 7, label: this.$t("market.starrateList.star7") },
      ];
    },
    skillList() {
      return [
        { value: 0, label: this.$t("market.skill.element1") },
        { value: 1, label: this.$t("market.skill.element2") },
        { value: 2, label: this.$t("market.skill.element3") },
        { value: 3, label: this.$t("market.skill.element4") },
        { value: 4, label: this.$t("market.skill.element5") },
        { value: 5, label: this.$t("market.skill.element6") },
      ];
    },
  },
  watch: {
    buyShow(n, o) {
      if (n) {
        this.getBalance();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.camp = to.query.val1;
    this.profession = to.query.val2;
    this.sortType = to.query.val3;
    this.sortType2 = to.query.val4;
    this.grade = to.query.val5;
    this.skillType = to.query.val6;
    this.pageFun(1);
  },
  created() { },
  mounted() {
    this.pageFun(1);
    if (!this.$storage.has('token')) {
      this.$store.commit("Set_infos", {});
    }
  },
  methods: {
    status(val) {
      switch (val) {
        case 0:
          return this.$t("card.status.operate");
          break;
        case 2:
          return this.$t("card.status.synchronizing");
          break;
        case 3:
          return this.$t("card.status.chain");
          break;
        case 4:
          return this.$t("card.status.confirmation");
          break;
        case 6:
          return this.$t("card.status.updategame");
          break;
        case 41:
          return this.$t("card.status.chainfail");
          break;
        case 40:
          return this.$t("card.status.syncfail");
          break;
        case 10:
          return this.$t("card.status.gameAssets");
          break;
        case 11:
          return this.$t("card.status.chain");
          break;
        case 12:
          return this.$t("card.status.walletAssets");
          break;
        case 14:
          return this.$t("card.status.synchronizing");
          break;
        case 15:
          return this.$t("card.status.auctioned");
          break;
        default:
          break;
      }
    },
    borderStatus(val, grade) {
      switch (val) {
        case 1:
          return 1;
          break;
        case 2:
          return 2;
          break;
        case 3:
          return 3;
          break;
        case 4:
          return 4;
          break;
        case 5:
          return grade == "SSR" ? "5" : "5_1";
          break;
        case 6:
        case 7:
        case 8:
        case 9:
          return 6;
          break;
        default:
          return 7;
          break;
      }
    },
    gradeStatus(val) {
      switch (val) {
        case "R":
          return "r";
        case "SR":
          return "sr";
        case "SSR":
          return "ssr";
        case "SSR+":
          return "ssrjia";
        case "SP":
          return "sp";
        default:
          break;
      }
    },
    tabChange(i) {
      this.tabindex = i
      this.$storage.set('tabindex', i)
      this.pageFun(1)
    },
    //清除筛选
    filterFun() {
      if (this.tabindex == 0) {
        this.camp = 0;
        this.profession = 0;
        this.starrate = 0;
        this.sortType = "new,desc";
      } else {
        this.grade = ""
        this.skillType = 0
        this.sortType2 = "new,desc";
      }
      this.pageFun(1);
    },
    //买入
    buyFun(item) {
      // 0-未激活，1-已激活
      if (this.infos.vip_active_status == 0) return this.$message(this.$t('account.activetips'))
      if (!this.$storage.has('token')) return this.$message(this.$t('messageBox.loginTip'))
      this.buyShow = true;
      this.infoObj = item;
    },
    //确认买入
    async toBuy() {
      const isAccount = await this.$store.dispatch("Check_account", {
        t: this,
      });
      if (!isAccount) return false;
      this.gunState = true;
      let auctionContract = new web3.eth.Contract(this.$store.state.auctionAbi, this.sets.auction_contract_address)
      console.log(auctionContract);
      auctionContract.methods.buy(this.sets.artifact_contract_address, this.infoObj.tokenid)
        .send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE, value: '0x' + (BigBumber(this.infoObj.price).times(Math.pow(10, 18))).toString(16).replace(/\.\w+$/, '') }, (error, result) => {
          if (error) {
            messageCollect({
              tokenid: this.infoObj.tokenid,
              error: JSON.stringify(error),
              result: ""
            }, { Serverid: this.$store.state.serverid })
          }
          this.gunState = false
          this.buyShow = false;
          console.log('buy=>', error, result)
          this.$message.success(this.$t("messageBox.buysuc"));
          this.pageFun(this.meta.current);
        })
      // this.cardbuy({
      //   item: this.infoObj,
      //   statusFun: this.statusFun,
      //   current: this.meta.current,
      // });
    },
    getBalance() {
      web3.eth.getBalance(this.infos.address).then((balance) => {
        this.balance = balance / Math.pow(10, 18);
        this.disabled = false;
      });
    },
    // 买入
    cardbuy() {
      if (this.balance - this.infoObj.price < 0) {
        this.gunState = false;
        return this.$message(this.$t("trans.err_able"));
      }
      this.$axios
        .post(
          this.$api.buy + "/" + this.infoObj.id,
          {},
          { Serverid: this.serverid }
        )
        .then((res) => {
          if (res.code == 200) {
            let contract = new web3.eth.Contract(
              this.$store.state.cardsAbi,
              this.sets.hero_contract_address
            );
            let amount =
              "0x" +
              (this.infoObj.order_price * Math.pow(10, 18))
                .toString(16)
                .replace(/\.\w+$/, "");
            contract.methods.bid(this.infoObj.tokenid).send(
              {
                from: this.infos.address,
                value: amount,
                gas: process.env.VUE_APP_GAS,
                gasPrice: process.env.VUE_APP_GAS_PRICE
              },
              (error, result) => {
                this.gunState = false;
                this.$axios
                  .post(
                    this.$api.buyback + "/" + this.infoObj.id,
                    {
                      txhash: result,
                      status: result ? 1 : error.code === 4001 ? 2 : 0,
                      msg: result ? this.$t("messageBox.ok") : error.message,
                    },
                    { Serverid: this.serverid }
                  )
                  .then((res1) => {
                    setTimeout(() => {
                      this.pageFun(this.meta.current);
                    }, 500);
                  })
                  .catch((err1) => {
                    setTimeout(() => {
                      this.pageFun(this.meta.current);
                    }, 500);
                  });
                this.$store.dispatch("Get_infos");
                if (error) this.$message(error.message);
                else if (result) {
                  this.buyShow = false;
                  this.$msgbox.alert(this.$t("trans.ok"), "", {
                    confirmButtonText: this.$t("trans.to"),
                    center: true,
                    callback: (action) => {
                      if (action === "confirm") {
                        this.$router.push("/activity");
                      }
                    },
                  });
                }
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.gunState = false;
        });
    },
    toDetail(item) {
      if (!this.$storage.has('token')) return this.$message(this.$t('messageBox.loginTip'))
      if (this.tabindex == 0) {
        this.$router.push({
          path: "/cardDetail",
          query: {
            id: item.game_symbol,
            type: "marketplace",
          },
        });
      } else {
        this.$router.push({
          path: "/dragonDetail",
          query: {
            item: JSON.stringify(item),
            id: item.game_symbol,
          },
        });
      }
    },
    //列表数据
    pageFun(page) {
      console.log(this.camp)
      this.loading = true;
      if (this.tabindex == 0) {
        this.$axios
          .get(
            this.$api.getMarketList,
            {
              camp: this.camp, //种族 1-人族，2-兽族，3-精灵，4-天使，5-恶魔
              profession: this.profession, //职业 2-法师,3-战士,4-坦克,5-辅助
              grade: this.grade, //级别 1-R, 2-SR, 3-SSR, 4-SSR+
              star: this.starrate, //星级
              order: this.sortType, //排序
              page,
              pageSize: 12,
            },
            { Authorization: 1 }
          )
          .then((res) => {
            this.loading = false;
            if (res.code == 200) {
              res.data.list.forEach((ele) => {
                try {
                  // if (moment(ele.bid_cuttime).diff(new Date(), "minutes") <= 0) ele.disabled = true
                  if (Number(item.left_time) <= 0) ele.disabled = true;
                  else ele.disabled = false;
                } catch (e) { }
                // let arrtibute = new setData().getData(res.data.list.attrs);
                // console.log(arrtibute);
                let name = partner_data1.data_partner_base[ele.attrs.heroid].name;
                // console.log(textdata.data_text[name]);
                ele.hero_name =
                  this._i18n.locale == "tw"
                    ? textdata.data_text[name].s_Tw
                    : this._i18n.locale == "en" ? textdata.data_text[name].s_En : textdata.data_text[name].s_Yn;
              });
              this.list = res.data.list;
              this.list.length <= 0
                ? (this.isempty = true)
                : (this.isempty = false);
              this.meta = {
                current: res.data.page,
                last: res.data.totalPage || 1,
                total: res.data.total,
              };
              console.log(this.list);
            }
          })
          .catch((err) => {
            this.isempty = true;
            this.loading = false;
          });
      } else {
        this.$axios.get(this.$api.getArtifactList, {
          page,
          pageSize: 12,
          grade: this.grade,//级别
          order: this.sortType2, //排序
          attribute: this.skillType, //技能属性 1火 2冰 3风 4光 5暗
        }, { Serverid: this.serverid })
          .then((res) => {
            res.data.list.forEach((ele, index) => {
              ele.type = index + 1;
              ele.dragonname_tw =
                textdata.data_text["4" + (ele.artifactid) + "00001"].s_Tw;
              ele.dragonname_en =
                textdata.data_text["4" + (ele.artifactid) + "00001"].s_En;
              ele.dragonname_in =
                textdata.data_text["4" + (ele.artifactid) + "00001"].s_Yn;
              ele.dragonname =
                this._i18n.locale == "tw"
                  ? textdata.data_text["4" + (ele.artifactid) + "00001"].s_Tw
                  : this._i18n.locale == "en" ? textdata.data_text["4" + (ele.artifactid) + "00001"].s_En :
                    textdata.data_text["4" + (ele.artifactid) + "00001"].s_Yn;
            });
            this.loading = false;
            this.dragonlist = res.data.list;
            this.dragonlist.length <= 0 ? (this.isempty = true) : (this.isempty = false);
            this.meta = {
              current: res.data.page,
              last: res.data.totalPage || 1,
              total: res.data.total,
            };
            // this.$storage.set("dragonsList", res.data);
          })
          .catch((err) => {
            this.isempty = true;
            this.loading = false;
          });
      }
    },
    async toSell(item, type) {
      if (!this.$storage.has('token')) return this.$message(this.$t('messageBox.loginTip'))
      const isAccount = await this.$store.dispatch("Check_account", {
        t: this,
      });
      if (!isAccount) return false;
      // 0-未激活，1-已激活
      if (this.infos.vip_active_status == 0) return this.$message(this.$t('account.activetips'))
      this.sellObj = item;
      this.sellObj.type = type;
      this.$refs.sellDom.sellShow = true;
    },
    // Take off the shelf
    takeOff(item) {
      this.sellObj = item;
      this.$msgbox.confirm(
        this.$t("account.shelfor") + "#" + item.tokenid,
        this.$t("messageBox.tip2"),
        {
          confirmButtonText: this.$t("messageBox.confirm"),
          cancelButtonText: this.$t("messageBox.cancel"),
          center: true,
          callback: (action) => {
            if (action === "confirm") {
              cancelCards(
                item.order_id,
                { price: this.amount },
                { Serverid: this.$store.state.serverid }
              ).then((res) => {
                // let orderId = res.data.orderId;
                let contract = new web3.eth.Contract(
                  this.$store.state.cardsAbi,
                  this.sets.hero_contract_address
                );
                contract.methods.cancelAuction(item.tokenid).send(
                  {
                    from: this.infos.address,
                    gas: process.env.VUE_APP_GAS,
                    gasPrice: process.env.VUE_APP_GAS_PRICE
                  },
                  (error, result) => {
                    if (error) {
                      if (error.code == 4001) {
                        this.$message("User denied transaction signature");
                      } else this.$message(error.message);
                    }
                    let data = {
                      txhash: result,
                      status: result ? 1 : error.code === 4001 ? 2 : 0,
                      msg: result ? this.$t("messageBox.ok") : error.message,
                    };
                    cancelCardsCallback(item.order_id, data, {
                      Serverid: this.$store.state.serverid,
                    }).then((res) => {
                      // this.$emit("callBack", { type: "sellDom", val:result?'success': error.message });
                      // this.callBack({
                      //   type: "sellDom",
                      //   val: result ? "success" : error.message,
                      // });
                      if (error.message != "success") return this.$message(error.message);
                      this.$msgbox.alert("#" + this.sellObj.tokenid + this.$t("account.shelgsuc"), this.$t("messageBox.tip2"),
                        {
                          confirmButtonText: this.$t("messageBox.confirm"),
                          center: true,
                          callback: (action) => {
                            if (action === "confirm") {
                              this.pageFun(1);
                            }
                          },
                        }
                      );
                    });
                  }
                );
              });
            }
          },
        }
      );
    },
    callBack({ type, val }) {
      switch (type) {
        case "reAuctionDom":
        case "auctionDom":
          this.$refs.sellDom.sellShow = false;
          if (val != "success") return this.$message(val);
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.auctionsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.pageFun(1);
                }
              },
            }
          );
          break;
        case "buyDom":
          this.$refs.sellDom.sellShow = false;
          if (val != "success") return this.$message(val);
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.reAuctionsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.pageFun(1);
                }
              },
            }
          );
          break;
        case "counts":
          val.disabled = true;
          break;
      }
    },
    async getBlock() {
      // console.log('getBlock=>',1)
      if (!this.sets.auction_contract_address) {
        setTimeout(() => {
          this.getBlock();
        }, 500);
        return false;
      }
      let auctionContract = new web3.eth.Contract(
        this.$store.state.auctionAbi,
        this.sets.auction_contract_address
      );
      auctionContract.methods
        .getExpireBlocks()
        .call({ from: this.infos.address })
        .then(async (block) => {
          console.log("getExpireBlocks=>", block);
          this.auctionBlock = block;
        });
    },
  },
};
</script>>

<style lang="scss" scoped>
/deep/ .el-form-item__label {
  width: 100%;
}
/deep/ .el-input__inner {
  // background-color: transparent !important;
  color: #fff !important;
}
/deep/ .el-radio {
  width: 50%;
  margin-right: 0;
  margin-top: 10px;
}
/deep/ .el-radio__inner {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: transparent;
  border-color: #317f6e;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #317f6e;
  background: #317f6e;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #2ad4af;
}
/deep/ .el-dialog {
  background: rgba(41, 43, 57, 0.9) !important;
  border: 1px solid #fffce5 !important;
  width: 380px !important;
}
/deep/ .el-dialog__body {
  height: auto !important;
}
.el-container {
  min-height: 100vh;
  position: relative;
  background: url("~@/assets/public/bg.jpg") no-repeat;
  background-attachment: fixed;
  background-size: 1920px 100%;
  background-position: center center;
  z-index: 2;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(33, 34, 47, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.el-aside {
  height: calc(100vh - 60px);
  border-right: 1px solid #999999;
  padding: 30px 16px 30px 60px;
  position: fixed;
  left: 0;
  top: 60px;
  .box {
    height: 500px;
    background: rgba(33, 36, 47, 0.6);
    border-radius: 4px;
    padding: 30px 20px;
    h3 {
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 36px;
        + span {
          font-size: 14px;
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      margin-top: 20px;
    }
  }
}
.el-main {
  background: rgba(33, 36, 47, 0.5);
  overflow: scroll;
  margin-left: 337px;
  .tab_box {
    width: 380px;
    max-width: 100%;
    height: 48px;
    // background: #005a46;
    border-radius: 4px;
    margin-bottom: 22px;
    .tab_box_item {
      width: 50%;
      height: 100%;
      line-height: 48px;
      background: #005a46;
      border-radius: 4px;
      color: rgba($color: #fff, $alpha: 0.5);
    }
    .tab_active {
      height: 100%;
      background: #317f6e;
      font-weight: bold;
      color: #fff;
    }
  }
  .box {
    .box_item {
      width: 280px;
      min-height: 470px;
      border: 1px solid #666;
      border-radius: 4px;
      position: relative;
      .item_title {
        color: #f3e9ca;
        font-size: 14px;
      }
      .hasactive2 {
        width: 100%;
        height: 30px;
        border-radius: 4px;
        line-height: 30px;
        border: 1px solid #37b497;
        background: none;
      }

      .relative_box {
        position: relative;
        width: 250px;
        height: 330px;
        .icon_box {
          width: 84px;
          height: 84px;
          position: absolute;
          left: 15px;
          top: 10px;
          z-index: 4;
          background: url("../assets/card/card_jiao.png") no-repeat;
          background-size: 100% 100%;
          p {
            transform: rotate(-45deg);
            margin-top: 13%;
          }
        }
      }
      .bid_price {
        color: #ff9000;
      }
      .imgbg {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .cardimg {
        position: relative;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .borders {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
      }
      .grade_img {
        position: absolute;
        z-index: 4;
        top: 15px;
        right: 15px;
      }
      .price {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: url("../assets/account/price.png") no-repeat;
        background-size: 100% 100%;
        bottom: 70px;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 4;
      }
      .camp_img {
        position: absolute;
        z-index: 4;
        bottom: 0px;
        left: 0px;
      }
      .pros_img {
        position: absolute;
        z-index: 4;
        bottom: 0px;
        right: 0px;
      }
      .timeout {
        width: 130px;
        height: 70px;
        background: url("../assets/account/jishi-bj.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 4;
        transform: translate(-50%, -50%);
      }
    }
    .box_item2 {
      min-height: 400px;
      .imgbg_box {
        width: 200px;
        height: 255px;
        background: url("../assets/account/bgframezs.png") no-repeat;
        background-size: 100% 100%;
        padding: 8px;

        .dragon {
          width: 70%;
          // height: 90%;
          position: absolute;
          bottom: 33px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }
        .jiaobiao {
          width: 74px;
          height: 74px;
          background: url("../assets/public/jiaobiao.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 3px;
          top: 3px;
          text-align: center;
          z-index: 2;

          span {
            display: block;
            transform: rotate(45deg);
            position: absolute;
            font-size: 14px;
            top: 18px;
            right: 5px;
          }
        }
        .block {
          width: 200px;
          height: 30px;
          // background: url("../assets/account/block2.png") no-repeat;
          // background-size: 100% 100%;
          position: absolute;
          bottom: 3px;
          left: 0;
          line-height: 30px;
          font-size: 14px;
          z-index: 2;
        }
      }
      .buy_btn {
        width: 100%;
        height: 30px;
        line-height: 30px;
        background: #317f6e;
        margin-top: 15px;
      }
    }
  }
  .default {
    border: 1px solid #999999;
    background: transparent;
    border-radius: 4px;
  }
}
.input_box {
  background-color: #3c3f4c;
  border-radius: 4px;
}
.input_item {
  height: 36px;
  background: #3c3f4c;
  border: 1px solid #3c3f4c;
  border-radius: 4px;
  padding: 0 9px;
  color: #fdfdfd;
}
@media screen and (max-width: 768px) {
  .marketplace {
    padding-left: 0;
  }
  .el-main {
    margin-left: 0;
    .box {
      justify-content: center;
    }
  }
}
</style>