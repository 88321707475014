<template>
  <div v-show="meta.last > 1" class="pagination d-flex ai-center jc-center">
    <span
      :class="['lines', { notallow: !meta.current - 1 > 0 }]"
      @click="pageFun(value - 1)"
    >
      <i class="el-icon-back fs-lg"></i>
    </span>
    <span>{{ $t("page.page") }}</span>
    <!-- <div class="inputBox mx-5">
      <el-input v-model="value" :min="1" :max="meta.last" class="input" />
    </div> -->
    <span class="lines inputBox"
      ><el-input
        type="number"
        v-model="value"
        @keyup.enter="nowFun(value)"
        @change="nowFun(value)"
        :min="1"
        :max="meta.last"
    /></span>
    <span>{{ $t("page.of") }} {{ meta.last }}</span>
    <span
      :class="['lines', { notallow: !meta.last - meta.current > 0 }]"
      @click="pageFun(value + 1)"
    >
      <i class="el-icon-right fs-lg"></i>
    </span>
  </div>
</template>

<script>
import { get } from "http";

export default {
  props: {
    meta: { type: Object },
  },
  data() {
    return {
      value: 1,
      // isPrev: this.meta.current - 1 > 0,
      // isNext: this.meta.last - this.meta.current > 0,
      // last: this.meta.last,
    };
  },
  watch: {
    meta(n, o) {
      // this.last = n.last;
      if (this.value != n.current) {
        this.value = n.current;
      }
    },
  },
  computed: {
    // last: {
    //   get() {
    //     return this.meta.last;
    //   },
    //   set(n) {
    //     console.log("newvalue=>", n);
    //   },
    // },
  },
  methods: {
    pageFun(page) {
      if (page - 1 < 0) return false;
      if (this.meta.last - page < 0) return false;
      // this.meta.last = page;
      this.$emit("pageFun", page);
    },
    nowFun(page) {
      if (isNaN(page)) return (this.value = this.meta.current);
      if (page - this.meta.last > 0) return (this.value = this.meta.current);
      if (page < 1) return (this.value = this.meta.current);
      this.pageFun(page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 35px;
  text-align: center;
  padding: 20px;
  span {
    display: inline-block;
    margin: 0 10px;
  }
  .lines {
    width: 80px;
    border: 1px solid #4c5158;
    border-radius: 4px;
    &:not(.notallow):not(.inputBox):hover {
      color: #046cfc;
      border-color: #046cfc;
      cursor: pointer;
    }
  }
  .inputBox {
    background-color: rgba(0, 0, 0, 0.4);
    .input {
      // width: 100px;
      height: 35px;
      line-height: 35px;
      color: #fff;
      text-align: center;
      background: transparent;
      border: none;
      outline: none;
    }
  }
}
</style>