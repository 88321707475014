<template>
  <el-dialog class="dialog-salecard" :visible.sync="sellShow" :modal="false"
    :title="infos.userid == sellObj.userid?$t('market.status.auctioncard'):$t('market.status.auctionadd')" center
    @close="amount=''">
    <el-form>
      <el-form-item>
        <div class="items item2 align-center flex-between">
          <div class="relative" style="width: 90px; height: 130px" v-if="sellObj.type == 'card'">
            <!-- 背景图 -->
            <img v-if="sellObj.attrs && sellObj.attrs.herostar && sellObj.attrs.grade" :src="
                require('@/assets/card/itemBigEx' +
                  borderStatus(sellObj.attrs.herostar, sellObj.attrs.grade) +
                  '.png')
              " width="77.5px" height="122.5px" class="imgbg" />
            <!-- 英雄图片 -->
            <img v-if="sellObj.attrs && sellObj.attrs.heroid"
              :src="require('@/assets/hero/' + sellObj.attrs.heroid + '.png')" width="80px" height="125px"
              class="cardimg" />
            <!-- 边框 -->
            <img v-if="sellObj.attrs && sellObj.attrs.herostar && sellObj.attrs.grade" :src="
                require('@/assets/card/bg_kuang' +
                  borderStatus(sellObj.attrs.herostar, sellObj.attrs.grade) +
                  '.png')
              " width="90px" height="130px" class="borders" />
            <!-- 级别 -->
            <div class="grade_img d-flex flex-column">
              <img v-if="sellObj.attrs && sellObj.attrs.grade" :src="
                  require('@/assets/card/' +
                    gradeStatus(sellObj.attrs.grade) +
                    '.png')
                " width="25px" />
              <img v-if="sellObj.attrs && sellObj.attrs.is_sp == 1" :src="require('@/assets/card/sp.png')" width="25px"
                class="m-t-10" />
            </div>
            <!-- 名字和星级 -->
            <div class="price t-color4 bold fs-md t-center">
              <div class="star d-flex ai-center jc-center" v-if="sellObj.attrs">
                <div class="d-flex ai-center" v-if="sellObj.attrs.herostar <= 5">
                  <img src="@/assets/card/star1big.png" width="8px" v-for="(sellObj, i) in sellObj.attrs.herostar"
                    :key="i" />
                </div>
                <div class="d-flex ai-center" v-if="sellObj.attrs.herostar > 5 && sellObj.attrs.herostar <= 9">
                  <img src="@/assets/card/star2big.png" width="8px" v-for="(sellObj, i) in sellObj.attrs.herostar - 5"
                    :key="i" />
                </div>
                <div class="d-flex ai-center" v-if="sellObj.attrs.herostar > 9 && sellObj.attrs.herostar <= 11">
                  <img src="@/assets/card/star3big.png" width="8px" v-for="(sellObj, i) in sellObj.attrs.herostar - 9"
                    :key="i" />
                </div>
              </div>
            </div>
            <!-- 种族 -->
            <img v-if="sellObj.attrs && sellObj.attrs.herocamp" :src="
                require('@/assets/card/bigcamp' +
                  sellObj.attrs.herocamp +
                  '.png')
              " width="20px" class="camp_img" />
            <!-- 职业 -->
            <img v-if="sellObj.attrs && sellObj.attrs.profession_type" :src="
                require('@/assets/card/type' +
                  sellObj.attrs.profession_type +
                  '.png')
              " width="20px" class="pros_img" />
          </div>
          <div class="img relative" v-if="sellObj.type == 'dragon'">
            <div class="jiaobiao" v-if="sellObj.tokenid"><span>#{{ sellObj.tokenid }}</span></div>
            <!-- <img :src="require(`@/assets/dragon/dragon${sellObj.artifactid}.png`)" class="dragon" /> -->
            <img v-if="sellObj.artifactid == 1" :src="require(`@/assets/dragon/fireDragon/dragon (${sellObj.images}).png`)" class="dragon" />
              <img v-if="sellObj.artifactid == 2" :src="require(`@/assets/dragon/windDragon/dragon (${sellObj.images}).png`)" class="dragon" />
              <img v-if="sellObj.artifactid == 3" :src="require(`@/assets/dragon/iceDragon/dragon (${sellObj.images}).png`)" class="dragon" />
              <img v-if="sellObj.artifactid == 4" :src="require(`@/assets/dragon/lightDragon/dragon (${sellObj.images}).png`)" class="dragon" />
              <img v-if="sellObj.artifactid == 5" :src="require(`@/assets/dragon/darkDragon/dragon (${sellObj.images}).png`)" class="dragon" />
          </div>
          <div class="relative hidden-sm-and-up"
            style="width: 100px; height: 130px; line-height: 26px; text-align: right;">
            <p class="t-color4" v-if="sellObj.type == 'card'">
              <span v-if="sellObj.attrs">Lv.{{ sellObj.attrs.herolevel }}</span>
              <span v-if="sellObj.hero_name">{{ sellObj.hero_name }}</span>
            </p>
            <p class="t-color4" v-else>
              <span v-if="sellObj.level">Lv.{{ sellObj.level }}</span>
              <span v-if="sellObj.dragonname"> {{ sellObj.dragonname }}</span>
            </p>
            <p><span class="t-color2">Token ID</span></p>
            <p><span class="t-color8">#{{ sellObj.tokenid ||'--' }}</span></p>
            <p><span class="t-color2">{{$t('trans.holder')}}</span></p>
            <p><span class="t-color8">{{ addressFilter(infos.address) }}</span></p>
          </div>
          <div class="relative hidden-xs-only" style="width: 168px; height: 130px">
            <p class="t-color4" v-if="sellObj.type == 'card'">
              <span v-if="sellObj.attrs">Lv.{{ sellObj.attrs.herolevel }}</span>
              <span v-if="sellObj.hero_name">{{ sellObj.hero_name }}</span>
            </p>
            <p class="t-color4" v-else>
              <span v-if="sellObj.level">Lv.{{ sellObj.level }}</span>
              <span v-if="sellObj.dragonname"> {{ sellObj.dragonname }}</span>
            </p>
            <p class="clr"><span class="flt t-color2">Token ID</span><span
                class="frt t-color8">#{{ sellObj.tokenid ||'--' }}</span></p>
            <p class="clr"><span class="flt t-color2">{{$t('trans.holder')}}</span><span
                class="frt t-color8">{{ addressFilter(sellObj.address) }}</span></p>
          </div>
        </div>
      </el-form-item>

      <el-form-item class="m-t-5">
        <p class="clr" style="lineheight: 30px; color: #fff">
          <span class="flt">{{ infos.userid == sellObj.userid?$t("market.status.minPrice"):$t("market.status.minAdd") }}
            <template v-if="minprice">{{minprice}}BNB</template>
          </span>
        </p>
        <el-input v-model="amount" autocomplete="off" clearable :min="minprice">
          <template #suffix>BNB</template>
        </el-input>
      </el-form-item>
      <el-form-item class="tc">
        <el-button :loading="loading" :disabled="disabled"
          :class="['w-100',sellObj.assets_status==12||sellObj.assets_status==13||sellObj.assets_status==15||sellObj.status==12||sellObj.status==13||sellObj.status==15?'hover':'notallow']"
          @click="sellFun">{{infos.userid == sellObj.userid?$t("market.status.auction"):$t("market.status.add")}}
        </el-button>
      </el-form-item>
    </el-form>
    <p style="font-size: 12px; color: #f4ab0b; line-height: 22px">
      <!-- {{ $t("messageBox.tip") }}:<br /> -->
      {{ $t("account.saletip1") }}<br />
      <!-- {{ $t("account.saletip", { fee: fee + "%" }) }} -->
    </p>
    <div style="padding:10px 15px;background: rgba(0,0,0,0.3);border-radius: 4px;color: #999999;"
      v-html='$t("account.saletip2")'>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { business, messageCollect } from "../api/common/index.api";
import BigBumber from "bignumber.js";
export default {
  data() {
    return {
      sellShow: false,
      loading: false,
      amount: "",
      fee: 1,
      price: null,
      minprice: null,
      disabled: true,
      balance: 0,
    };
  },
  props: {
    sellObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    sellShow: {
      handler(n, o) {
        if (n) {
          this.loading = false
          this.getPrice()
        } else {
          this.price = null
          this.minprice = null
          this.amount = ''
          this.disabled = true
        }
      }
    }
  },
  mounted() {
  },
  computed: {
    ...mapState({
      sets: (state) => state.sets,
      infos: (state) => state.infos,
    }),
  },
  methods: {
    addressFilter(str) {
      if (!str) return "--";
      const str1 = str.substring(0, 5);
      const str2 = str.substring(str.length - 4, str.length);
      return str1 + "..." + str2;
    },
    borderStatus(val, grade) {
      switch (val) {
        case 1:
          return 1;
          break;
        case 2:
          return 2;
          break;
        case 3:
          return 3;
          break;
        case 4:
          return 4;
          break;
        case 5:
          return grade == "SSR" ? "5" : "5_1";
          break;
        case 6:
        case 7:
        case 8:
        case 9:
          return 6;
          break;
        default:
          return 7;
          break;
      }
    },
    gradeStatus(val) {
      switch (val) {
        case "R":
          return "r";
        case "SR":
          return "sr";
        case "SSR":
          return "ssr";
        case "SSR+":
          return "ssrjia";
        case "SP":
          return "sp";
        default:
          break;
      }
    },
    async sellFun() {
      // console.log('sellFun=>',1)
      let cardsContract;
      console.log(this.infos.userid == this.sellObj.userid);
      if (!this.amount) return this.$message(this.$t('account.p_saleprice'))
      if (Number(this.amount) <= 0) return this.$message(this.$t("trans.p_numberda"));
      // if (this.amount - this.minprice < 0) return this.$message(this.$t(this.infos.userid == this.sellObj.userid ? "market.status.minPrice" : "market.status.minAdd") + this.minprice)
      this.loading = true
      if (this.sellObj.type == 'card') {
        cardsContract = new web3.eth.Contract(this.$store.state.cardsAbi, this.sets.hero_contract_address)
      } else {
        cardsContract = new web3.eth.Contract(this.$store.state.dragonAbi, this.sets.artifact_contract_address)
      }
      let auctionContract = new web3.eth.Contract(this.$store.state.auctionAbi, this.sets.auction_contract_address)
      if (this.infos.userid == this.sellObj.userid) {
        let flag = true
        if (this.sellObj.assets_status == 12 || this.sellObj.status == 12) {
          // console.log('approveAddress=>',1)
          const approveAddress = await cardsContract.methods.getApproved(this.sellObj.tokenid).call().then(res => {
            console.log('approveAddress=>', res)
            return res
          })
          if (approveAddress.toLowerCase() != this.sets.auction_contract_address.toLowerCase()) {
            flag = false
            // console.log('approve=>',1)
            await cardsContract.methods.approve(
              this.sets.auction_contract_address,
              this.sellObj.tokenid,
            ).send({
              from: this.infos.address,
              gas: process.env.VUE_APP_GAS,
              gasPrice: process.env.VUE_APP_GAS_PRICE
            }, (error, result) => {
              console.log('approve=>', error, result)
              if (error) {
                this.loading = false
                messageCollect({
                  tokenid: this.sellObj.tokenid,
                  error: JSON.stringify(error),
                  result: ""
                }, { Serverid: this.$store.state.serverid })
                if (error.code == 4001) this.$message("User denied transaction signature");
                else this.$message(error.message);
              } else if (result) flag = true
            })
          }
        } else if (this.sellObj.assets_status == 15 || this.sellObj.status == 15) {
          // console.log('ownerOfAddress=>',1)
          const ownerOfAddress = await auctionContract.methods.ownerOf(this.sellObj.type == 'card' ? this.sets.hero_contract_address : this.sets.artifact_contract_address, this.sellObj.tokenid).call().then(res => {
            console.log('ownerOfAddress=>', res)
            return res
          })
          if (ownerOfAddress.toLowerCase() != this.infos.address.toLowerCase()) {
            this.$message(this.$t('account.reAuctionfail'))
            flag = false
          }
        }
        if (flag) {
          if (this.price == 0) {
            console.log('getBlockNumber=>', 1)
            const block = await window.web3.eth.getBlockNumber()
            // console.log('auction=>',1)
            console.log("this.amount", this.amount);
            auctionContract.methods.auction(
              this.sellObj.type == 'card' ? this.sets.hero_contract_address : this.sets.artifact_contract_address,
              this.sellObj.tokenid,
              '0x' + (BigBumber(this.amount).times(Math.pow(10, 18))).toString(16).replace(/\.\w+$/, ''),
              this.sets.exchange_fee_address,
              this.sets.airdrop_erc20_address,
              '0x' + (BigBumber(this.sets.airdrop_amount).times(Math.pow(10, 18))).toString(16).replace(/\.\w+$/, ''),
              '0x' + (block + 100).toString(16).replace(/\.\w+$/, ''),
            ).send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE }, (error, result) => {
              business({ txhash: result }, { Serverid: this.$store.state.serverid })
              if (error) {
                messageCollect({
                  tokenid: this.sellObj.tokenid,
                  error: JSON.stringify(error),
                  result: ""
                }, { Serverid: this.$store.state.serverid })
              }
              console.log('auction=>', error, result)
              this.$emit("callBack", { type: "auctionDom", val: result ? 'success' : error.message });
            })
          } else {
            console.log('reAuction=>', 1)
            let price1 = BigBumber(this.amount).div(100).toFixed()
            let newprice = '0x' + (BigBumber(price1).times(Math.pow(10, 18))).toString(16).replace(/\.\w+$/, '')
            console.log("this.amount2", newprice);
            auctionContract.methods.reAuction(
              this.sellObj.type == 'card' ? this.sets.hero_contract_address : this.sets.artifact_contract_address,
              this.sellObj.tokenid,
              '0x' + (BigBumber(this.amount).times(Math.pow(10, 18))).toString(16).replace(/\.\w+$/, ''),
              this.sets.airdrop_erc20_address,
              '0x' + (BigBumber(0).times(Math.pow(10, 18))).toString(16).replace(/\.\w+$/, '')
            ).send({ from: this.infos.address, value: newprice, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE }, (error, result) => {
              business({ txhash: result }, { Serverid: this.$store.state.serverid })
              if (error) {
                messageCollect({
                  tokenid: this.sellObj.tokenid,
                  error: JSON.stringify(error),
                  result: ""
                }, { Serverid: this.$store.state.serverid })
              }
              console.log('reAuction=>', error, result)
              this.$emit("callBack", { type: "reAuctionDom", val: result ? 'success' : error.message });
            })
          }
        }
      } else {
        console.log('buy=>', 1)
        auctionContract.methods.buy(
          this.sellObj.type == 'card' ? this.sets.hero_contract_address : this.sets.artifact_contract_address,
          this.sellObj.tokenid,
        ).send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE, value: '0x' + (BigBumber(this.amount).times(Math.pow(10, 18))).toString(16).replace(/\.\w+$/, '') }, (error, result) => {
          business({ txhash: result }, { Serverid: this.$store.state.serverid })
          if (error) {
            this.loading = false
            messageCollect({
              tokenid: this.sellObj.tokenid,
              error: JSON.stringify(error),
              result: ""
            }, { Serverid: this.$store.state.serverid })
          }
          console.log('buy=>', error, result)
          this.$emit("callBack", { type: "buyDom", val: result ? 'success' : error.message });
        })
      }
    },
    handleCutZero(num) {
      //拷贝一份 返回去掉零的新串
      let newstr = num;
      //循环变量 小数部分长度
      // console.log(num.indexOf('.') - 1);
      let leng = num.length - num.indexOf('.') - 1;
      //判断是否有效数
      if (num.indexOf('.') > -1) {
        //循环小数部分
        for (let i = leng; i > 0; i--) {
          //如果newstr末尾有0
          if (
            newstr.lastIndexOf('0') > -1 &&
            newstr.substr(newstr.length - 1, 1) == 0
          ) {
            let k = newstr.lastIndexOf('0');
            //如果小数点后只有一个0 去掉小数点
            if (newstr.charAt(k - 1) == '.') {
              return newstr.substring(0, k - 1);
            } else {
              //否则 去掉一个0
              newstr = newstr.substring(0, k);
            }
          } else {
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return num;
    },
    getPrice() {
      // console.log('getPrice=>',1)
      let auctionContract = new web3.eth.Contract(this.$store.state.auctionAbi, this.sets.auction_contract_address)
      // auctionContract.methods.getLastDealPrice(
      //   this.sets.hero_contract_address,
      //   this.sellObj.tokenid
      // ).call({from: this.infos.address}).then(async price=>{
      //   console.log('getLastDealPrice=>',price)
      // if(Number(price)){
      //   this.price = Math.ceil((BigBumber(price).div(Math.pow(10, 18)).toFixed())*1.11*Math.pow(10,6))/Math.pow(10,6);
      //   this.disabled = false;
      //   if(this.infos.userid != this.sellObj.userid) {
      //     this.minprice = this.price
      //     this.amount = this.price
      //   }
      // }else{
      console.log("type", this.sellObj.type);
      console.log("tokenid", this.sellObj.tokenid);
      auctionContract.methods.getCurrentPrice(
        this.sellObj.type == 'card' ? this.sets.hero_contract_address : this.sets.artifact_contract_address,
        this.sellObj.tokenid
      ).call({ from: this.infos.address }).then(async price1 => {
        console.log('getCurrentPrice=>', price1)
        const val = this.handleCutZero(BigBumber(price1).div(Math.pow(10, 18)).toFixed())
        this.price = Math.ceil(val * Math.pow(10, 6)) / Math.pow(10, 6);
        // if (this.price.indexOf('.') != -1) {
        //   const res = this.price.substring(this.price.indexOf('.') + 1, this.price.length)
        //   if(res.charAt(7-1)&&Number(res.charAt(7-1))>0){

        //   }
        // }
        // this.price = Math.ceil((BigBumber(price1).div(Math.pow(10, 18)).toFixed()) * Math.pow(10, 6)) / Math.pow(10, 6);
        this.disabled = false;
        console.log("this.price", this.price);
        if (this.infos.userid != this.sellObj.tokenid) {
          this.minprice = this.price
          this.amount = this.price
        }
      })
      // }
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  height: 38px;
  background: #3e3e4e;
  border: 1px solid #32363c;
  border-radius: 4px;
  color: #fff;
  padding: 0 15px;
}
.items {
  // width: 280px;
  height: 320px;
  background: #3e3e4e;
  border-radius: 4px;
  padding: 10px 20px;
  .name {
    font-size: 14px;
    font-weight: 400;
    color: #f3e9ca;
  }
  .img {
    width: 145px;
    height: 155px;
    background: url("../assets/account/bgframezs.png") no-repeat;
    background-size: 100% 98%;
    padding: 8px;

    .dragon {
      width: 100%;
      height: 100%;
    }

    .persale {
      position: absolute;
      right: -10px;
      top: -10px;
    }

    .jiaobiao {
      width: 54px;
      height: 54px;
      background: url("../assets/public/jiaobiao.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;

      span {
        display: inline-block;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        right: 0px;
        color: #fff;
        font-size: 12px;
      }
    }

    .price {
      width: 170px;
      height: 30px;
      background: url("../assets/account/price.png") no-repeat;
      background-size: 100% 100%;
      bottom: 10px;
      line-height: 30px;
      font-size: 14px;
    }
    .block {
      width: 170px;
      height: 50px;
      background: url("../assets/account/block2.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 3px;
      left: 0;
      line-height: 50px;
      font-size: 14px;
    }
  }
  .opration {
    width: 180px;
    height: 38px;
    background: #317F6E;
    border-radius: 4px;
    line-height: 38px;
  }
  .hasactive2 {
    border: 1px solid #37B497;
    background: none;
  }
  .default {
    border: 1px solid #999999;
    background: transparent;
  }
  .activing {
    background: #999;
    cursor: wait;
  }
  .hasactive {
    border: 1px solid #999999 !important;
    background: transparent !important;
  }
}
.item2 {
  height: auto;
  .imgbg {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cardimg {
    position: relative;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .borders {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
  }
  .icon_box {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    background: url("../assets/card/card_jiao.png") no-repeat;
    background-size: 100% 100%;
    p {
      transform: rotate(-45deg);
      margin-top: 13%;
    }
  }
  .icon_box2 {
    background: url("../assets/card/card_jiao2.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon_box3 {
    background: url("../assets/card/card_jiao3.png") no-repeat;
    background-size: 100% 100%;
  }
  .forsale {
    width: 160px;
    height: 90px;
    background: url("../assets/card/card_sell.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }
  .price {
    width: 100%;
    // height: 30px;
    line-height: 30px;
    background: url("../assets/account/price.png") no-repeat;
    background-size: 100% 100%;
    bottom: 15%;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 4;
    padding: 5px 0;
  }
  .grade_img {
    position: absolute;
    z-index: 4;
    top: 5px;
    right: 5px;
  }
  .camp_img {
    position: absolute;
    z-index: 4;
    bottom: -2px;
    left: -2px;
  }
  .pros_img {
    position: absolute;
    z-index: 4;
    bottom: -2px;
    right: -2px;
  }
}
.dialog-salecard {
  .el-form-item {
    margin-bottom: 5px;
  }
}
</style>