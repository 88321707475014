<template>
  <span style="color:#FF8A00" class="bold fs-xl">{{length}}</span>
</template>
<script>
import moment from 'moment'
import { mapState } from "vuex";
import BigNumber from 'bignumber.js';
export default {
  props: ['item', "auctionBlock"],
  data() {
    return {
      ends: ''
    }
  },
  methods: {
    secondToTime(second) {
      var seconds = Number(second)
      if (seconds > 0) {
        //转换小时数
        var hour = parseInt(seconds / 3600);
        //剩余的秒数
        var seconds1 = seconds % 3600;
        //得到分
        var minute = Math.floor(seconds1 / 60);
        //剩余的秒
        // var seconds2 = seconds % 60;
        if (hour < 10) hour = "0" + hour
        if (minute < 10) minute = "0" + minute
        return hour + " : " + minute
      } else {
        return "00 : 00"
      }
      // return hour == 0 ? (minute == 0 ? seconds + "秒" : minute + "分钟" + seconds + "秒") : minute + "时" + minute + "分钟" + seconds + "秒";

    },
  },
  computed: {
    ...mapState({
      sets: (state) => state.sets,
    }),

    length() {
      // const block = this.sets.bsc_block_time.split('-')[0]
      // const start = this.sets.bsc_block_time.split('-')[1]
      // const length = (this.item.last_block - block + Number(this.auctionBlock))*3000
      // this.ends = moment(start*1000+length).diff(moment(),"minutes")


      // this.ends = moment(this.item.bid_cuttime+'+08:00').diff(moment().utc(),"minutes")            
      // if(this.ends<=0) {
      //     this.$emit('callBack',{type:'counts',val:this.item})
      //     return '00:00'
      // }
      // setTimeout(()=>{
      //     this.ends --
      // },1000*10)
      // let h = parseInt(this.ends/60)
      // let m = this.ends%60
      // if (h<10) h = '0'+h
      // if (m<10) m = '0'+m
      // return h +':'+ m

      // this.ends = moment.utc(Number(this.item.left_time) * 1000).format("HH:mm")
      this.ends = this.secondToTime(this.item.left_time)
      console.log("this.ends", this.ends);
      return this.ends


      // this.ends = moment(this.item.bid_cuttime).diff(new Date(),"seconds")
      // if(this.ends<=0) {
      //     this.$emit('callBack',{type:'counts',val:this.item})
      //     return '00:00:00'
      // }
      // setTimeout(()=>{
      //     this.ends --
      // },1000)
      // let h = parseInt(this.ends/3600)
      // let m = parseInt((this.ends%3600)/60)
      // let s = this.ends%60
      // if (h<10) h = '0'+h
      // if (m<10) m = '0'+m
      // if (s<10) s = '0'+s
      // return h +':'+ m +':'+ s
    }
  }
}
</script>